import React from "react"
import styled from "@emotion/styled"
import BasicTable from './basicTable';
import AccentBox from './accentBox';
import SmallBox from './smallBox';
import { Link } from "gatsby";
import Img from "./image";

const InfoTable = (meta, props) => {
  return (
    <>
      <Img {...props} name={meta.name} alt={meta.fname}/>
      <BasicTable className={'center top-margin'}>
        <tbody>
          <tr>
            <th>コスパ</th>
            <th>デザイン</th>
            <th>サポート</th>
            <th>人気</th>
          </tr>
          <tr>
            <td>{meta.cospa}</td>
            <td>{meta.design}</td>
            <td>{meta.support}</td>
            <td>{meta.popular}</td>
          </tr>
        </tbody>
      </BasicTable>
    </>
  )
};

const Head = props => {
  return <h3><span className="rank"/>{props.title}</h3>
};

class Maker extends React.PureComponent {
  render() {
    const { meta } = this.props;
    switch(meta.name) {
      case 'mouse':
        return (
          <div id={meta.name}>
            <Head title="マウスコンピュータ | 安心感とコスパの絶妙なバランス"/>
            {InfoTable(meta, this.props)}
            <p>乃木坂のCMにより有名となった株式会社マウスコンピュータが運営するパソコンストア。東京都中央区に本社があり、液晶モニターメーカーであるイーヤマを吸収合弁しておりiiyamaブランドのモニター販売も手がける。</p>
            <p>マウスコンピュータはパソコンの構成に無駄が無く、<strong><b>目的ごとに幅広いラインナップを揃えている</b></strong>ため、容易に自分に合ったパソコンを探すことができるだろう。</p>
            <p>また一部上場企業であり、パソコンに詳しくない人もターゲットとしているため、信頼性や<strong><b>24時間365日サポート</b></strong>など体制も厚い。</p>
            <p>ゲーミングパソコンにも力を入れており、特に<strong><b>NEXTGEARというネット限定モデルは3Dゲームに十分なスペックを高コスパで提供</b></strong>しており、映像編集やAIなどにも最適である。</p>
            <p>総じてコスパも高くバランスが良いため、はじめてネットでパソコンを購入するというケースでは特におすすめしたいメーカーである。<Link to="/makers/mouse-computer/">マウスコンピュータの評判はこちら</Link></p>
            <AccentBox title="サブブランド">
              <li><a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=887394567&vc_url=https%3A%2F%2Fwww.mouse-jp.co.jp%2Fstore%2Fbrand%2Fnextgear%2F" rel="nofollow noopener" target="_blank"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=887394567" height="1" width="0" border="0" alt=""/>NEXTGEAR</a>: マウスのネット販売限定の格安ゲーミングブランド。クリエイティブ用途にも最適。</li>
              <li><a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886902210" rel="nofollow noopener" target="_blank"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886902210" height="1" width="1" border="0" alt=""/>G-Tune</a>: マウスのゲーミングブランド。ハイスペックタワー型から軽量10時間駆動ノートなど製品力も高い。</li>
              <li><a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886902208" rel="nofollow noopener" target="_blank"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886902208" height="1" width="1" border="0" alt=""/>DAIV</a>: クリエイター向けのブランド。Adobe After Effectsを使用する人や3Dモデリングを行う人などプロのクリエイティブ作業に最適なモデル。</li>
            </AccentBox>
            <SmallBox type="word" text="公式サイトへ">
              <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886847755" rel="nofollow noopener" target="_blank"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886847755" height="1" width="1" border="0" alt=""/>マウスコンピュータ</a>
            </SmallBox>
          </div>
        )
      case 'koubou':
        return (
          <div id={meta.name}>
            <Head title="パソコン工房 | リーゾナブルで抜群のスペック"/>
            {InfoTable(meta, this.props)}
            <p>信頼性が高いBTOの老舗メーカー。パソコンの中級者が好んで購入する。</p>
            <p>実はマウスコンピュータ系列の会社であるものの運営・商品開発は全く別である。マウスコンピュータと比べ広告宣伝費を抑えられるのかコストパフォーマンスが高い。</p>
            <p>通常のデスクトップパソコンからゲーミングパソコンまで幅広く展開し、<strong>ローエンドからハイエンドまで全てのパソコンで<b>価格が安定して安い</b></strong>。</p>
            <p>価格の比較検討をする時にはチェックしておきたいメーカーである。<Link to="/makers/pckoubou/">パソコン工房の評判はこちら</Link></p>
            <SmallBox type="word" text="公式サイトへ">
              <a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=83593.10000209&type=3&subid=0" target="_blank" rel="nofollow noopener">パソコン工房</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=83593.10000209&type=3&subid=0"/>
            </SmallBox>
          </div>   
        )
      case 'lenovo':
        return (
          <div id={meta.name}>
            <Head title="Lenovo | IBMから分離した世界シェアトップメーカー"/>
            {InfoTable(meta, this.props)}
            <p>IBMから分離した世界最大シェアを誇る中国メーカー。世界シェア25%を誇り大量発注による仕入れ値の抑制、大量生産による<strong><b>コストパフォーマンスは群を抜く</b></strong>。</p>
            <p><strong>製品ラインナップは<b>全方位</b></strong>にわたり、ゲーミングからモバイルノート、一体型デスクトップまで全ての種類をカバーする。</p>
            <p>ブランドとしては、もともとIBMのパソコン部門だっただけのことはあり<strong><b>セキュリティ面にも強み</b></strong>を持ち、ThinkPadはビジネス向けにおすすめのモデルである。</p>
            <p>また<strong>ゲーミングブランド<b>LEGIONはコスパがメーカー随一</b></strong>のため、コスパの高いゲーミングパソコンを検討している場合は要チェックである。<Link to="/makers/lenovo/">Lenovoの評判はこちら</Link></p>
            <SmallBox type="word" text="公式サイトへ">
              <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=884764152" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=884764152" height="1" width="1" border="0" alt=""/>Lenovo</a>
            </SmallBox>
          </div>
        )
      case 'dell':
        return (
          <div id={meta.name}>
            <Head title="デル | コスパの黒船、圧倒的価格競争力"/>
            {InfoTable(meta, this.props)}
            <p>アメリカ合衆国のテキサス州に本社を置く大手パソコンメーカーで世界シェアも常にトップグループに入る。</p>
            <p><strong>Lenovoと同様製品ラインナップは<b>全方位</b></strong>であり、ゲーミングからモバイルノート、一体型デスクトップまで全てのジャンルを揃える。</p>
            <p>Lenovoはコスパの良いモデルに悪いモデルが紛れ込んでいるイメージだが、<strong>デルは安定して<b>全ての製品でコスパが高い</b></strong>ため、デルにぼったくられたという話は耳にしない。</p>
            <p>特に10万円以下でそこそこ高性能なノートパソコン・スリム型デスクトップが欲しいという時に重宝するメーカーが私の中でのイメージ。</p>
            <p>基本的にパソコンは海外から運ばれるため、納期は遅めで、またサポートも訛りのある日本語だったりするので注意が必要なこともある。<Link to="/makers/dell/">デルの評判はこちら</Link></p>
            <SmallBox type="word" text="公式サイトへ">
            <a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=296568.10006794&type=3&subid=0" target="_blank" rel="nofollow noopener">デル (ノート)</a><img width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=296568.10006794&type=3&subid=0" /> | <a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=296568.10006795&type=3&subid=0" target="_blank" rel="nofollow noopener">デル (デスクトップ)</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=296568.10006795&type=3&subid=0" />
            </SmallBox>
          </div>
        )
      case 'frontier':
        return (
          <div id={meta.name}>
            <Head title="フロンティア | ゲーミングデスクトップの雄"/>
            {InfoTable(meta, this.props)}
            <p>BTOの草分け的なブランド。もともとはフロンティア神代という会社だったが、経営の悪化からヤマダ電気の完全子会社となり、同様にヤマダ子会社のインバースが事業を引き継いでいる。</p>
            <p>フロンティアは<strong><b>ゲーミングデスクトップを格安で手に入れたい時</b>に真っ先に考えるべきブランド</strong>である。セール品は神コスパと名高い。</p>
            <p>その他ノートパソコンも販売しており、あまり特筆すべきことはないものの、<strong>14インチで軽量・長時間駆動な高性能ノートは製品力が高い</strong>ので要チェック。</p>
            <SmallBox type="word" text="公式サイトへ">
            <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886578503"  target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886578503" height="1" width="1" border="0" alt=""/>フロンティア</a>
            </SmallBox>
          </div>
        )
      case 'fujitsu':
        return (
          <div id={meta.name}>
            <Head title="富士通 | インテリア重視の高級パソコン"/>
            {InfoTable(meta, this.props)}
            <p>携帯電話でダサいと話題のアローズを擁する富士通が何故？と目を疑う<strong>インテリアを重視したラインナップを展開</strong>する。</p>
            <p>リビングルームに溶け込む一体型デスクトップやスタンダードノート、<strong>600g代で<b>世界最軽量のモバイルノート</b></strong>などいずれも安売りモデルとは一線を画した製品が多い。</p>
            <p>軽量モバイルパソコンを求める大学生・ビジネスマンや一家で共有できる一体型デスクトップを探している人には、やや高価であるがおすすめしたいメーカーである。<Link to="/makers/fujitsu/">富士通の評判はこちら</Link></p>
            <p>なお、<strong><b>富士通株式会社様より通常より割引率が高いシークレットクーポンを提供していただいている</b></strong>ため購入時には是非利用してほしい。</p>
            <SmallBox type="word" text="公式サイトへ">
            <a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=183092.10000156&type=3&subid=0" target="_blank" rel="nofollow noopener">富士通</a><img border="0" width="1" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=183092.10000156&type=3&subid=0" alt=""/> | <Link to="/fujitsu/">シークレットクーポンページへ</Link>
            </SmallBox>
          </div>
        )
      case 'hp':
        return (
          <div id={meta.name}>
            <Head title="HP | デザインとコスパのバランスが秀逸"/>
            {InfoTable(meta, this.props)}
            <p><strong>デザイン性が高く、コスパも良いメーカー</strong>がシリコンバレーに本社を構えるHP(ヒューレッドパッカード)である。</p>
            <p>他の海外メーカーであるデル、Lenovoと同様世界で高いシェアを持ち、ゲーミングから一体型デスクトップ、モバイルノートまで<strong><b>全方位</b>の製品ラインナップ</strong>を手がける。</p>
            <p><strong>特に<b>13.3インチのモバイルノート</b>は人気が高く</strong>、私の周りでもデルやLenovoより少し高くても良いから洗練されたデザインのパソコンが欲しいという層から絶大に支持されている。</p>
            <p>一方で東京ゲームショウなどにも出店しているゲーミングブランドのOMENを支持している人の話は今のところ聞いたことが無い。</p>
            <SmallBox type="word" text="公式サイトへ">
            <a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=252926.507&type=3&subid=0" target="_blank" rel="nofollow noopener">HP</a><img border="0" width="1" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=252926.507&type=3&subid=0" alt=""/>
            </SmallBox>
          </div>
        )
      case 'nec':
        return (
          <div id={meta.name}>
            <Head title="NEC | 国内シェアトップで安心の使い勝手"/>
            {InfoTable(meta, this.props)}
            <p><strong>国内シェアは20%超</strong>でトップを走るNEC。ノートパソコンの品質・サポート対応・デザインどれをとっても高いレベルである。</p>
            <p>コスパはあまり高くはない。しかし<strong><b>初心者に優しい初期ソフト</b>、<b>懇切丁寧な説明書</b>、<b>充実したWebサイトでのQ&A</b>など簡単にパソコンを使うための工夫</strong>が随所にみられ、購入後にやりたいことができなくて困るという事は少ないであろう。</p>
            <p>製品力も然ることながら、この<strong>安心感が国内の高いシェアに繋がっている</strong>のである。</p>
            <p>またビジネス用ノートでは軽量でハイスペックなパソコンを販売していて、こちらは上級者にもおすすめできる。</p>
            <p>最後に、<strong><b>PCスペックが低くて失敗する可能性が高いメーカー</b></strong>のため、<Link to="/cpu/">CPU性能比較表</Link>などで必ず妥当なCPUかチェックしておこう。<Link to="/makers/nec/">NECの評判はこちら</Link></p>
            <SmallBox type="word" text="公式サイトへ">
            <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=885175200&vc_url=http%3A%2F%2Fnec-lavie.jp%2Fshop%2F%3Fsc_cid%3Djp%3Aaffiliate%3Atgniag" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=885175200" height="1" width="0" border="0" alt=""/>NEC</a>
            </SmallBox>
          </div>
        )
      case 'dynabook':
        return (
          <div id={meta.name}>
            <Head title="Dynabook | シャープと東芝の融合、最強のモビリティ性能"/>
            {InfoTable(meta, this.props)}
            <p>世界初のノートパソコンを発売した技術力を持つ東芝と、鴻海の製造力や低消費電力のIGZO液晶を持つシャープによる合弁会社として誕生したのがDynabookである。ノートパソコン専業で、デスクトップは撤退している。</p>
            <p>特にモバイルノートの完成度は非常に高く、<strong>軽く、薄く、コンパクトでありながら堅牢性や長時間駆動というモビリティに重要な性能も高いレベルで実現していることから<b>最強のモバイルノート</b></strong>と名高い。</p>
            <p>大手国内メーカーならではのサポートも手厚く、安心して購入できることから大学生向けのパソコンとしても人気が高い。</p>
            <p>なお、<strong><b>Dynabook株式会社様からの提供により、下記特設サイトにより通常より高い割引率で購入が可能</b></strong>である。【ID:dyna204cls PASS:T8Y7GRSV】</p>
            <SmallBox type="word" text="公式サイトへ">
            <a href="https://linksynergy.jrs5.com/fs-bin/click?id=xsv0FDnJs1M&offerid=233988.10000411&type=3&subid=0" target="_blank" rel="nofollow noopener">Dynabook</a><img border="0" width="1" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=233988.10000411&type=3&subid=0" alt=""/> | <a href="https://linksynergy.jrs5.com/fs-bin/click?id=xsv0FDnJs1M&offerid=233988.10001860&type=3&subid=0" target="_blank" rel="nofollow">特設サイトへ</a><img width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=233988.10001860&type=3&subid=0" />
            </SmallBox>
          </div>
        )
      case 'asus':
        return (
          <div id={meta.name}>
            <Head title="ASUS | 世界シェアトップのゲーミングノート"/>
          {InfoTable(meta, this.props)}
          <p>台湾に本社を置くパソコンメーカー。エイスースと読む。パソコンの土台となるマザーボードで世界No1のシェアを占めるなど製品開発力が高いメーカーである。</p>
  <p>特に<strong><b>ゲーミングノートの国内シェアはNo1</b></strong>であり、冷却性能の向上を考えて液体金属を採用したROG Strixなど勝つための細かいこだわりが見られる。</p>
  <p>また、<strong>高級機種Zenbook/Expertbookは<b>ノートパッドにテンキーを表示する</b>作り</strong>のため、テンキーのためにモバイルノートを諦めていた人には良い選択肢となる。</p>
  <p>他、Amazon経由での購入も多いが、<strong>シリアルナンバーさえ残っていれば<b>故意に壊そうとも保証が受けられる</b>ASUSの安心保障</strong>は公式ストアの魅力の一つであるので両方見ると良いだろう。</p>
            <SmallBox type="word" text="公式サイトへ">
            <a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=858354.11&type=3&subid=0" target="_blank" rel="nofollow noopener">ASUS</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=858354.11&type=3&subid=0"/> | <a href="https://amzn.to/2WGN85G" target="_blank" rel="nofollow noopener">Amazonで見る</a>
            </SmallBox>
          </div>
        )
      case 'microsoft':
        return (
          <div id={meta.name}>
            <Head title="Microsoft | Office標準付属と質感を極めた高級感"/>
          {InfoTable(meta, this.props)}
          <p><strong>旧来のパソコンにデザイン性をプラスしたパソコン界のダイソン</strong>でありモバイルノートSurfaceを中心に販売している。持ち歩く事が多いモバイルノートにおいて、デザインは重要な要素である。</p>
<p>カフェマックはテンプレ過ぎて新鮮さに欠けるため、カフェサーフェスの方がおしゃれな感はある。<strong>特に<b>女性と大学生の間で人気が高い</b></strong>。</p>
<p>タッチパネルやペンの感度も良好であり、新しいパソコンの使い方を模索している人にもおすすめできるかもしれない。</p>
<p>また、<strong><b>Microsoft Office Home & Businessが標準で付属する</b>ので、オフィスソフト込みで考えるとコスパも良好</strong>。</p>
            <SmallBox type="word" text="公式サイトへ">
            <a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=791390.9&type=3&subid=0" target="_blank" rel="nofollow noopener">Microsoft(Surface)</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=791390.9&type=3&subid=0"/>
            </SmallBox>
          </div>
        )
      case 'apple':
        return (
          <div id={meta.name}>
            <Head title="Apple(Macbook) | 他社を圧倒するブランド力"/>
          {InfoTable(meta, this.props)}
          <p>MacOSを搭載し、ハードウェアからソフトウェアまで一貫して手がけており、デザインや安定した性能には定評がある。</p>
  <p>Windowsとは異なるため使い勝手は独特で、<strong>右クリックが無い、ディレクトリの構造が分かりにくいなど<b>とっつきにくい点が多く</b></strong>、Windowsに慣れた人にはかなり使いにくい。
  </p>
  <p>しかしノートパッドの感触が心地が良く、特にアプリの切り替え操作が直感的に行いやすいため、そのような作業が多い<strong><b>プログラマーやクリエイティブな職種を中心として人気が高い</b></strong>。</p>
  <p>購入は量販店か、ネットで買うなら<a href="https://amzn.to/3mNSzu7" target="_blank" rel="nofollow noopener">ギフト券チャージ</a>してAmazonで買うのも安いと思われる。</p>
            <SmallBox type="word" text="公式 / 販売サイトへ">
            <a href="https://www.apple.com/jp/mac/" target="_blank" rel="nofollow noopener">Apple Store</a> | <a href="https://amzn.to/30p8CW7"  target="_blank" rel="nofollow noopener">Amazonで見る</a> | <a href="https://hb.afl.rakuten.co.jp/hgc/0ca19ae3.d60bbcdb.19675804.52da794d/?pc=https%3A%2F%2Fsearch.rakuten.co.jp%2Fsearch%2Fmall%2Fmacbook%2F100040%2F"  target="_blank" rel="nofollow noopener">楽天で見る</a>
            </SmallBox>
          </div>
        )
      case 'seven':
        return (
          <div id={meta.name}>
            <Head title="SEVEN | ハイスペックなデスクトップパソコンが欲しいならここ"/>
          {InfoTable(meta, this.props)}
          <p>株式会社セブンアールジャパンという小規模な会社が製造・販売をしているパソコン。製造から販売まで秋葉原で行い、上級者向けのコアなパソコンも販売。</p>
<p>もともとある程度パソコンに詳しい人しか購入しないため、サポート等はそれほど期待できないが、<strong>ローエンドのものからプロ顔負けの動画編集に使うような<b>超ハイスペックなカスタマイズパソコンまで高いコスパ</b>で手に入れることができる</strong>。</p>
<p>スペックからパソコンを絞り込め、価格順で並び変える事ができるので、すぐに希望のパソコンを探し比較検討を行うことができる。</p>
<p><strong><b>タワー型デスクトップパソコン</b>を検討しているならば必ずチェックすべき</strong>である。</p>
            <SmallBox type="word" text="公式サイトへ">
            <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886899662" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886899662" height="1" width="1" border="0" alt=""/>SEVEN</a>
            </SmallBox>
          </div>
        )
      case 'tsukumo':
        return (
          <div id={meta.name}>
            <Head title="TSUKUMO | キャラコラボが豊富で楽しさが増幅"/>
          {InfoTable(meta, this.props)}
          <p>2009年にヤマダ電機に買収されたパソコンメーカーで、秋葉原にて企画、設計を行っている。最新パーツをいち早くパソコンに搭載し、価格も低価格。パソコンパーツのショップとしても有名である。</p>
<p>コスパがとりわけ高いわけではないが、<strong>初音ミクやVtuberとの<b>キャラクターコラボ</b>が多く</strong>気になる存在である。</p>
<p>他にもCLIP Studio、写真のRAW現像推奨、4画面マルチモニタモデルなど<strong>細かい用途に応じておすすめパソコンを提供してくれており、痒いところに手が届くメーカー</strong>である。</p>
            <SmallBox type="word" text="公式サイトへ">
            <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886899687" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886899687" height="1" width="1" border="0" alt=""/>TSUKUMO</a>
            </SmallBox>
          </div>
        )
      case 'vaio':
        return (
          <div id={meta.name}>
          <Head title="VAIO | ビジネスに特化した端子が豊富なノートパソコン"/>
          {InfoTable(meta, this.props)}
          <p>ソニーから分離された長野県安曇野に本社を置くパソコンメーカー。</p>
          <p><strong><b>売れ筋は12.5インチの軽量ノートパソコン</b>。パソコンを外部に持ち運び、プレゼンを行うようなサラリーマンを特に対象</strong>としている。</p>
          <p>小さいボディにDVI端子、複数のUSB端子、LANケーブルなど<strong><b>豊富なインターフェース</b>を備えビジネスシーンのあらゆる環境に適用できる</strong>事が最大の魅力と言える。</p><p>ちなみに私の会社用パソコンもVAIOが標準となっている。ビジネスシーンでは兎に角便利なパソコンである。</p>
          <SmallBox type="word" text="公式サイトへ">
          <a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=596216.7&type=3&subid=0&LSNSUBSITE=LSNSUBSITE" target="_blank" rel="nofollow noopener">VAIO</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=596216.7&type=3&subid=0" />
          </SmallBox>
          </div>
        )
      case 'storm':
        return (
          <div id={meta.name}>
            <Head title="ストーム | こだわりのケースでハイスペックデスクトップを"/>
          {InfoTable(meta, this.props)}
          <p>一般販売の他、BtoBにも力を入れる老舗メーカー。<strong>熱効率の良い電源、ファーストベンダーのメモリなど<b>細部に渡る高品質なパーツ</b></strong>、<strong>アルミ仕様やキューブ型といった<b>デザイン性が高いPCケース</b></strong>などこだわりが強い。</p>
  <p>正直なところ、10万円未満のパソコンは、部品の高性能さが仇ととなりコストパフォーマンスが悪いが、<strong><b>値段が高いパソコンほどお得感が増してくる</b></strong>。</p>
  <p>知名度こそ低いものの、ハイエンドなデスクトップパソコンを探している場合はおすすめのメーカーである。</p>
            <SmallBox type="word" text="公式サイトへ">
            <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886899671"  target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886899671" height="1" width="1" border="0" alt=""/>ストーム</a>
            </SmallBox>
          </div>
        )
      case 'panasonic':
        return (
          <div id={meta.name}>
            <Head title="Panasonic | データを確実に守る凹凸モバイルノートパソコン"/>
            {InfoTable(meta, this.props)}
          <p>パナソニックはLet's Noteという凹凸ボディーのモバイルノートパソコンを販売している。</p>
  <p>特筆すべきは凹凸ボディが衝撃を吸収し<strong><b>落としても壊れない</b>という一点に尽きる。</strong></p>
  <p>この特徴と運用サポート体制から法人向けのパソコンとして高い顧客満足度を実現している。</p>
  <p>個人として購入するならば、データの消失を確実に防ぎたい場合である。また他の特徴としては<strong><b>モバイルノートでDVDドライブが付属する点</b>とノートパッドが円形で意外と使いやすい点</strong>が挙げられる。</p>
            <SmallBox type="word" text="公式サイトへ">
            <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886899682" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886899682" height="1" width="1" border="0" alt=""/>Panasonic</a>
            </SmallBox>
          </div>
        )
      case 'lg':
        return (
          <div id={meta.name}>
            <Head title="LG | スタンダードノートにも徹底的な軽量化を実現"/>
          {InfoTable(meta, this.props)}
          <p>テレビや液晶モニターで定評がある韓国メーカー。日本でも高画質なテレビを低価格で販売しており見かける人も多いだろう。</p>
<p>液晶の低消費電力技術を活かして<b>軽量でロングバッテリーのgram</b>というパソコンを販売している。<strong><b>17インチのノートパソコンで1350グラム</b>は常識はずれの軽さ</strong>である。</p>
<p>ロングバッテリーを搭載しているため、作業のしやすい大型のノートパソコンを外でも使いたいという需要に答えてくれるだろう。</p>
            <SmallBox type="word" text="公式サイトへ">
            <a href="https://amzn.to/3hbDb8p" target="_blank" rel="nofollow noopener">Amazonで見る</a> | <a href="https://hb.afl.rakuten.co.jp/hgc/0ca19ae3.d60bbcdb.19675804.52da794d/?pc=https%3A%2F%2Fsearch.rakuten.co.jp%2Fsearch%2Fmall%2Flg%2Bgram%2B%25E3%2583%258E%25E3%2583%25BC%25E3%2583%2588%25E3%2583%2591%25E3%2582%25BD%25E3%2582%25B3%25E3%2583%25B3%2F" target="_blank" rel="nofollow noopener">楽天で見る</a>
            </SmallBox>
          </div>
        )
      default:
        break;
    }
  }
}

const RankWrapper = styled.div({
  '.rank:before' : {
    counterIncrement: 'head',
    content: `counter(head)"位:"`
  }
});

export default class MakerRankingDescription extends React.Component {
  render() {
    const makerArray = this.props.makerArray;
    return (
      <RankWrapper>
      {makerArray.map(maker => {
        return <Maker {...this.props} meta={maker} key={maker.name}/>
      })}
      </RankWrapper>
    )
  }
}